

<template>
  <div class="Fince_Cw_C">
    <div class="tab_Top">
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect">
        <el-menu-item index="1">工资单</el-menu-item>
        <el-menu-item index="2">账单核对</el-menu-item>
        <el-menu-item index="3">资金管理</el-menu-item>
        <el-menu-item index="4">发票管理</el-menu-item>
      </el-menu>
      <div class="Men_1">
        说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。
      </div>
    </div>

    <div>
      <div class="Tables" style="padding-bottom:18px">
        <div class="Trys" style="margin-right:500px">
          <div class="uiius" style="margin-top:10px">转账类型：</div>
          <div class="uiius" :class="actit == '0' ? 'sddsdd': ''" style="margin-top:8px;margin-left:10px"  @click="AUOP('0')">全部</div>
          <div class="uiius" :class="actit == 'redpack' ? 'sddsdd': ''" style="margin-left:30px;margin-top:8px" @click="AUOP('redpack')">微信红包</div>
          <div class="uiius" :class="actit == 'transfer' ? 'sddsdd': ''" style="margin-left:30px;margin-top:8px" @click="AUOP('transfer')">微信零钱</div>
          <div class="uiius" :class="actit == 'paybank' ? 'sddsdd': ''" style="margin-left:30px;margin-top:8px" @click="AUOP('paybank')">银行卡(个人)</div>
        </div>
        <div class="Trys" style="margin:15px 500px 10px 0">
          <div class="uiius" style="margin-top:8px">转账状态：</div>
          <div class="uiius" :class="actit2 == 0 ? 'sddsdd': ''" style="margin-left:10px" @click="AUOP2(0)">全部</div>
          <div class="uiius" :class="actit2 == 20 ? 'sddsdd': ''" style="margin-left:30px" @click="AUOP2(20)">转账成功</div>
          <div class="uiius" :class="actit2 == 30 ? 'sddsdd': ''" style="margin-left:30px" @click="AUOP2(30)">转账失败</div>
        </div>   
        <div class="Trys Tiii" style="margin-right:580px;margin-top:15px">
          <div class="uiius" style="margin: 6px 0 0 0px;">转出日期：</div>
          <el-date-picker v-model="Months1" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
          <span style="margin: 5px 10px;">至</span>
          <el-date-picker v-model="Months2" type="month" placeholder="选择月份" :clearable="false"></el-date-picker>
          <el-button style="margin-left:15px" size="small" type="primary" @click="Btn_chaxun(2)">查询</el-button>
          <el-button size="small" @click="Btn_chongzhi">重置</el-button>
        </div>
      </div>
      <div class="Tables">
        <div class="Tab_T" @click="Add_kq(1)" v-if="TextMoMo('finance:withdraw:payment')"><img class="Img_ti" src="../../assets/img/+++.png" alt="">发起转账</div>
        <div class=" Tab_y" @click="Staff_del3(2)" style="border:1px solid #FF4D4F;color:#FF4D4F"
          v-if="TextMoMo('workbench:sales:middle:read')"><i class="el-icon-delete" style="color:#FF4D4F"></i>批量删除</div>
        <div class="dsds" style="width: 30%;float: right;">
          <div class="el_seso"><i class="el-icon-search" @click="inpu_sech"></i></div>
          <el-input v-model="input_seach" placeholder="请输入内容" @keyup.enter.native="inpu_sech()"></el-input>
        </div>

        <el-table
          :data="tableData2"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="select_change2">
          <el-table-column
            type="selection"
            width="55"
            align="center"></el-table-column>
          <el-table-column
            prop="id"
            label="ID"
            width="55"
            align="center"></el-table-column>
          <el-table-column align="center" prop="realname" label="收款人" width="140"></el-table-column>
          <el-table-column align="center" prop="createtime" label="转账时间" width="250"></el-table-column>
          <el-table-column align="center" prop="pay_type_text" label="转账类型" width="190"></el-table-column>
          <el-table-column align="center" prop="staff.realname" label="操作人" width="210"></el-table-column>
          <el-table-column align="center" prop="pay_status_text" label="转账状态" width="210"></el-table-column>
          <el-table-column align="center" prop="pay_status" label="金额" width="190"></el-table-column>
          <el-table-column align="center" label="操作">
            <template #default="scope">
              <div class="el_butto">
                  <el-button type="text" v-if="TextMoMo('finance:withdraw:read')" @click="handEdit2(2, scope.row)" >查看</el-button>
                  <el-button type="text" v-if="!TextMoMo('finance:withdraw:read')">查看</el-button>
              </div>
              <div class="el_butto" style="margin-left:13px ">
                  <el-button type="text" v-if="TextMoMo('finance:withdraw:del')" @click="handEdit2(1, scope.row)" >编辑</el-button>
              </div>
              <div class="el_butto rere">
                  <el-button
                  type="text"
                  class="red" v-if="TextMoMo('finance:withdraw:columns')"
                  @click="handDelete2(scope.row)"
                  >删除</el-button>
              </div>

            </template>
          </el-table-column>
        </el-table>
        <div class=""></div>
        <div class="block">
          <el-pagination
            background
            @current-change="handChange3"
            :current-page="currentPage2"
            :page-size="page.pageSize"
            layout="total, prev, pager, next, jumper"
            :total="total2">
          </el-pagination>
        </div>
      </div>
    </div>
    
    <!--发起转账-->
    <el-dialog title="发起转账" v-model="editVisible" width="25%"> 
      <div class="Terer">
        <div class="Tatt_a">
          <span :class="actit3 == 1 ? 'actyty' : ''" @click="Wxhb(1)">微信红包</span>
          <span :class="actit3 == 2 ? 'actyty' : ''" @click="Wxhb(2)">微信零钱</span>
          <span :class="actit3 == 3 ? 'actyty' : ''" @click="Wxhb(3)">微信转卡</span>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方户名：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.realname" placeholder="请填写持卡人姓名" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput" v-if="actit != 3">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方openid：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.openid" placeholder="请填写收款人openid" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方账号：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.bank_card" placeholder="只支持向个人银行账户付款" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款开户行：
            </div>
            <div class="Rttyyy">
              <el-select  v-model="Crojj.bank_open" placeholder="请选择正确的开户行信息" @change="Chang_ses" style="width:100%">
                  <el-option  v-for="item in bankList" :key="item" :label="item" :value="item" ></el-option>
              </el-select>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>打款金额：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.money" placeholder="请填写需要付款的金额" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>打款事由：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.reason" placeholder="请填写打款事由" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>密码：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.password" placeholder="请输入密码" class="El_ii"></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>备注：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.remark" placeholder="请选择员工" class="El_ii"></el-input>
            </div>
        </div>
         <div class="Dityo">
          <div class="dialog-footer" style="float:right">
            <el-button @click="editVisible = false">取 消</el-button>
            <el-button type="primary" @click="BTN_Hb" style="margin-left:15px">保存设置</el-button>
          </div>
        </div> 
      </div>

    </el-dialog>
    <!--发起转账-->

    <!--发起转账-->
    <el-dialog title="查看转账" v-model="editVisible1" width="25%"> 
      <div class="Terer">
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方户名：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.realname" placeholder="请填写持卡人姓名" class="El_ii" disabled></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput" v-if="actit != 3">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方openid：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.openid" placeholder="请填写收款人openid" class="El_ii" disabled></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款方账号：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.bank_card" placeholder="只支持向个人银行账户付款" class="El_ii" disabled></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>收款开户行：
            </div>
            <div class="Rttyyy">
              <el-select  v-model="Crojj.bank_open" placeholder="请选择正确的开户行信息" @change="Chang_ses" disabled style="width:100%">
                <el-option  v-for="item in bankList" :key="item" :label="item" :value="item" ></el-option>
              </el-select>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>打款金额：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.money" placeholder="请填写需要付款的金额" class="El_ii" disabled></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>打款事由：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.reason" placeholder="请填写打款事由" class="El_ii" disabled></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>密码：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.password" placeholder="请输入密码" class="El_ii" disabled></el-input>
            </div>
        </div>
        <div class="Rig_D Rl_nput">
            <div class="Rig_Ddddd" style="margin-bottom:8px"> 
              <span style="color:#FF4D4F">* </span>备注：
            </div>
            <div class="Rttyyy">
            <el-input v-model="Crojj.remark" placeholder="请选择员工" class="El_ii" disabled></el-input>
            </div>
        </div>
         <div class="Dityo">
          <div class="dialog-footer" style="float:right">
            <el-button @click="editVisible1 = false">取 消</el-button>
          </div>
        </div> 
      </div>

    </el-dialog>
    <!--发起转账-->
  </div>
</template>

<script>
import axios from 'axios'
import  TextMoth  from "../../components/utils.vue"; 
export default {
  name: "basetable",
  data() {
    return {
      actit:0,
      actit2:0,
      actit3:1,
      P_actitA_1:0,
      P_actitA_2:0,
      P_actitA_3:0,

      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},

      Months11:'',
      Months1:'',
      Months22:'',
      Months2:'',
      fapiao_id:0,

      activeIndex: "3",
      el_table_Data:[],

      bankList:[],
      el_table_Data_realname:'',

      Mooo1:'',
      Mooo2:'',

      input_seach:'',
      show1: false,
      show11: false,
      show2: false,
      editVisible:false,
      editVisible1:false,
      input: "",
      tableData2: [],
      tableData3:[],
      total:0,
      total2:0,
      total3:0,
      currentPage2:0,
      currentPage3:0,
      selection_arr2:[],     // 资金管理选中
      selection_arr3:[],     // 资金管理选中
      id: -1,
      Crojj:{
        pay_type: "redpack", //转账类型，示例：redpack=红包，transfer=零钱，paybank=银行卡
        password: "123456789", //密码，示例：123456789
        realname: "张三", //收款人姓名
        // openid: "asdadsdsadasdsd", //收款人openid，类型为红包和零钱时必填
        bank_card: "50000000000000000000", //银行卡号-类型为银行卡时必填
        bank_open: "工商银行", //开户行-类型为银行卡时必填
        money: 100, //转账金额
        reason: "采购", //打款事由
        remark: "" //备注
      }
    };
  },
  mounted() {
    var that = this
    that.BBBBB()

    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
  },
  methods: {
    TextMoMo(e) {
      var arr = TextMoth.getData(e)
      return arr  
    },
    Close() {
      this.dialogVisible3 = false;
      this.dialogVisible4 = false;
      this.dialogVisible5 = false;
      this.dialogVisible6 = false;
      this.dialogVisible7 = false;
      this.show1 = false;
      this.show2 = false;
      this.show3 = false;
    },
    Add_kq(e) {  //
        var that = this
        if (e == 1) {
          that.editVisible = true
          that.Crojj = {}
          that.Crojj.pay_type = 'redpack'
        } else if (e == 2) {
          that.show1 = true
        }
    },
    
    AUOP(e) {
      console.log(e)
      var that = this
      that.actit = e
      that.Btn_chaxun()
    },
    AUOP2(e) {
      var that = this
      that.actit2 = e
      that.Btn_chaxun()
    },
    P_AUOP1(e) {
      var that = this
      that.P_actitA_1 = e      
    },
    P_AUOP2(e) {
      var that = this
      that.P_actitA_2 = e      
    },
    P_AUOP3(e) {
      var that = this
      that.P_actitA_3 = e     
    },
    Wxhb(e) {         //微信转账类型切换
      var that = this
      that.actit3 = e
      if(e == 1) {
        that.Crojj.pay_type = 'redpack'
      }else if (e == 2) {
        that.Crojj.pay_type = 'transfer'
      }else if (e == 3) {
        that.Crojj.pay_type = 'paybank'
      }
    },

    handleSelect(key, keyPath) {               ////////////////////////////////////
      var that = this
      console.log(key, keyPath);
      that.input_seach = ''
      that.actit = 0
      that.actit2 = 0
      that.actit3 = 1
      that.Months11 = ''
      that.Months1 = ''
      that.Months22 = ''
      that.Months2 = ''
      if (key == 1) {
        that.$router.push('/Workbench/Fince_cw')
      } else if(key == 2){
        that.$router.push('/Workbench/Fince_cw_B')
      } else if (key == 3) {
        that.$router.push('/Workbench/Fince_cw_C')
      } else if (key == 4) {
        that.$router.push('/Workbench/Fince_cw_D')
      }
    },
    inpu_sech(e) {   // 搜索材料
        var that = this
          that.currentPage2 = 0,
          axios({ 
            method: 'post',
            url: '/admin/finance/withdraw/index',    
            data: {
              page: 1,
              offset:0,
              limit: 10,
              filter:{
                  keyword:that.input_seach
                }
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
              }

          })

    }, 

    BTN_Hb() {      //发起转账
     var that = this
        axios({   
          method: 'post',
          url: '/admin/finance/withdraw/payment',    
          data: {
            row:that.Crojj
          },
        }).then(function (res) {
          console.log(res)
          if (res.data.code == 0) {
             that.$message.warning(res.data.msg);
          }else {
             that.$message.success(res.data.msg);
            that.editVisible = false
            that.BBBBB()
          }
        })         
    },
    Chang_ses() {
      var that = this
      console.log(that.Crojj.bank_open)
    },
    handDelete2(e) {
        var that = this
        var arr  = []
        arr.push(e.id)
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
        axios({
            method: 'delete',
            url: '/admin/finance/withdraw/del',
            data: {
              ids: arr
            },
          }).then(function (res) {
            that.$message.success(res.data.msg);
            axios({ 
              method: 'post',
              url: '/admin/finance/withdraw/index',    
              data: {
                page: that.currentPage2,
                offset:0,
                limit: 10,
              },
            }).then(function (res) {
                if (res.code == 401) {
                  that.$router.push('/Login');
                } else {
                  that.total2 = res.data.data.total
                  that.tableData2 = res.data.data.rows
                }

            })
          })                 
        })
    },
    Btn_chongzhi() {    //重置
      var that = this
      that.Months11 = '',
      that.Months22 = '',
      that.Mooo1 = '',
      that.Mooo2 = ''
      that.input_seach = ''
      that.actit = 0
      that.actit2 = 0
      that.Months1 = ''
      that.Months2 = ''
    },

    Btn_chaxun(e) {        //查询
        var that = this
        var time = ''
        var time2 = ''
          if (that.Months1 != '' ) {
            var date = new Date(that.Months1)
            
            var y = date.getFullYear()
            var m = date.getMonth() + 1
            m = m < 10 ? ('0' + m) : m
            var d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            time = y + '-' + m + '-' + d
          }
          if (that.Months2 != '') {
            var date2 = new Date(that.Months2)
            var y2 = date2.getFullYear()
            var m2 = date2.getMonth() + 1
            m2 = m2 < 10 ? ('0' + m2) : m2
            var d2 = date2.getDate()
            d2 = d2 < 10 ? ('0' + d2) : d2
            time2 = y2 + '-' + m2 + '-' + d2
          }
          axios({
            method: 'post',
            url: '/admin/finance/withdraw/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
              filter:{
                pay_type: that.actit,
                pay_status: that.actit2,
                time:{
                  min:time,
                  max:time2,
                },
                keyword:that.input_seach
              }
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
              }
          })

    },
    handChange3(val) {     // 分页3
      var that = this
      that.currentPage3 = val
        axios({ 
          method: 'post',
          url: '/admin/finance/withdraw/index',    
          data: {
            page: val,
            limit:10,
            filter:{
                keyword:that.input_seach
            }
          },
        }).then(function (res) {
            if (res.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
            }

        })
    },

    BBBBB() {                                         //转账列表
          var that = this
          axios({ 
            method: 'post',
            url: '/admin/finance/withdraw/index',    
            data: {
              page: 1,
              offset:0,
              limit:10,
            },
          }).then(function (res) {
              if (res.code == 401) {
                 that.$router.push('/Login');
              } else {
                that.total2 = res.data.data.total
                that.tableData2 = res.data.data.rows
                that.bankList = res.data.data.bankList
              }

          })
    },

    handEdit2(e, t) {      //资金管理查看
      var that = this
      if (e == 1) {
        that.editVisible = true
      } else if (e == 2) {
        that.editVisible1 = true
      }
      axios.get('/admin/finance/withdraw/read',{   // 模块
        params:{
            id:t.id
        }
        }).then(function (res) {
          that.el_table_Data = res.data.data.row
          that.el_table_Data_realname = res.data.data.row.staff.realname
      })
      

    },
 
    select_change2(sel) {   // 资金管理勾选
      var that = this
      var searr = []
      for (let i = 0; i < sel.length; i++) {
        const ele = sel[i].id;
        searr.push(ele)
      }
      that.selection_arr2 = searr

    },

    Staff_del3(e) {   // 批量删除
      var that = this
        that.$confirm("确定要删除吗？", "提示", {
            type: "warning"
        }).then(() => {
          if (e == 2) {
            var arr = []
            for (let i = 0; i < that.selection_arr2.length; i++) {
              const element = that.selection_arr2[i];
              arr.push(element)
            }
            axios.delete('/admin/finance/withdraw/del',{   // 模块
              params:{
                    ids: arr
              }
            }).then(function (res) {
            if (res.code == 0) {
                  that.$message.warning(res.data.msg);
            } else{ 
                that.$message.success(res.data.msg);
                axios({ 
                  method: 'post',
                  url: '/admin/finance/withdraw/index',    
                  data: {
                    page: that.currentPage2,
                    offset:0,
                    limit:10,
                  },
                }).then(function (res) {
                    if (res.code == 401) {
                      that.$router.push('/Login');
                    } else {
                      that.total2 = res.data.data.total
                      that.tableData2 = res.data.data.rows
                      that.bankList = res.data.data.bankList
                    }

                })
            }
          })
          } else if(e == 3) {
            console.log(that.selection_arr3)
            var arr = []
            for (let i = 0; i < that.selection_arr3.length; i++) {
              const element = that.selection_arr3[i];
              arr.push(element)
            }
            console.log(arr)
            axios.delete('/admin/customer/invoice/del',{   // 模块
              params:{
                    ids: arr
              }
              }).then(function (res) {
              if (res.code == 0) {
                    that.$message.warning(res.data.msg);
              } else{ 
                  that.$message.success(res.data.msg);
                    axios({ 
                      method: 'post',
                      url: '/admin/customer/invoice/index',    
                      data: {
                        page: that.currentPage3,
                        offset:0,
                        limit: 10,
                      },
                    }).then(function (res) {
                        if (res.code == 401) {
                          that.$router.push('/Login');
                        } else {
                          that.total3 = res.data.data.total
                          that.tableData3 = res.data.data.rows
                        }

                    })
              }
            })            
          }

        })
    },
  
  },
};
</script>

<style scoped>    
.Fince_Cw_C .uiius {
  display: inline-block;
  padding: 4px 12px;
  height: 25px;
  line-height: 25px;
  background: #fff; 
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
  margin-top: 6px;
  cursor: pointer;
}
.Fince_Cw_C .sddsdd {
  background: #0081ff;
  color: #fff!important;
  display: inline-block;
  padding: 4px 12px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  cursor: pointer;
}
.Fince_Cw_C .Tatt_a {
  width: 315px;
  height: 34px;
  background: #1890FF;
  border-radius: 16px;
  margin-bottom: 30px;
}
.Fince_Cw_C .Tatt_a span {
  display: inline-block;
  text-align: center;
  width: 102px;
  height: 28px;
  line-height: 30px;
  color: #fff; 
  border-radius: 14px;
  margin-top: 3px;
  margin-left: 2px;
  cursor: pointer;
}
.Fince_Cw_C .Rig_Ddddd { 
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  width: 117px;
  float: left;
  margin-top: 11px;
  margin-right: 13px; 
} 
.Fince_Cw_C .actyty {
  background: #fff;
  color: #1890FF!important;
}
.Fince_Cw_C .sadasd table td {
  background:#FFF;
  padding-left: 10px;
  height: 36px;
  font-size: 14px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #595959;
}  
</style>
